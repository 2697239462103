import { ForecastsGroupMembersFragment } from '../../generated/graphql';
import React from 'react';
import { Pronostix } from '../../assets/icons';
import { Score } from '../Score';
import { DEFAULT_PROFILE_PICTURE } from '../../constants';
import { Forecast_Status_Enum } from '../../../functions/src/generated/graphql';

interface GroupMemberForecastProps {
  forecast: ForecastsGroupMembersFragment;
}

type ColorsVariants = Record<Forecast_Status_Enum, string>;

const borderColorVariants: ColorsVariants = {
  miss: 'border-red-500',
  partial: 'border-orange-500',
  perfect: 'border-green-500',
};

const iconColorVariants: ColorsVariants = {
  miss: 'text-red-500',
  partial: 'text-orange-500',
  perfect: 'text-green-500',
};

export const GroupMemberForecast: React.FC<GroupMemberForecastProps> = ({ forecast }) => {
  const { profile, estimatedAway, estimatedHome, status } = forecast;

  return (
    <div
      className={`flex rounded-md bg-gray-100 px-4 py-2 w-full border-l-4 ${
        status ? borderColorVariants[status] : 'border-blue-500'
      } `}
    >
      <div className="flex">
        <img
          className="w-6 h-6 rounded-full"
          src={profile.user.photoUrl ?? DEFAULT_PROFILE_PICTURE}
          alt="group member"
        />
        <span className="font-semibold ml-2">{profile.user.pseudo}</span>
      </div>
      <div className="ml-auto flex items-center">
        <Pronostix className={`w-5 h-5 mr-2 ${status ? iconColorVariants[status] : 'text-blue-500'}`} />
        <Score scoreHomeTeam={estimatedHome} scoreAwayTeam={estimatedAway} />
      </div>
    </div>
  );
};
