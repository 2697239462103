import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import clsx from 'clsx';
import { Field, Form, Formik } from 'formik';

import * as icons from '../../../assets/icons';
import { ArrowDown, Copy, Edit, Quit, Star, Trash } from '../../../assets/icons';
import { Dropdown, DropdownTrigger, MenuItem } from '../../../components/Dropdown';
import { useToggle } from '../../../hooks/useToggle';
import { Button } from '../../../components/Button';
import { MatchEmpty } from '../../../assets/illustration';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import {
  GetGroupAndInfoQuery,
  GetUserGroupsByProfileIdQuery,
  Order_By,
  useCreateInvitationForGroupMutation,
  useEditGroupInfosMutation,
  useGetAllGamesForGroupWithForecastsCountQuery,
  useGetGroupAndInfoQuery,
  useGetInvitationForGroupQuery,
} from '../../../generated/graphql';
import { ForecastPill } from '../../../components/PersonalProfileHeader';
import { Loader } from '../../../components/Loader';
import { LoadingFullScreen } from '../../../layout/LoadingFullScreen';
import { useOrdinalNumber } from '../../../hooks/useOrdinalNumber';
import { MatchPhaseGroup } from '../../../components/MatchPhaseGroup';
import { RankItem } from '../../../components/RankItemGroupMember';
import { Modal, useModal } from '../../../components/Modal';
import { Select } from '../../../components/Select';
import { showToast } from '../../../components/Toast';
import { LinkButton } from '../../../components/LinkButton';
import { DEFAULT_GROUP_PICTURE } from '../../../constants';
import { DeleteGroupModal, LeaveGroupModal } from './index';
import { imageValidationSchema, UploadImageInput } from '../../../components/UploadImageInput';
import { fileToBase64 } from '../../../utils/fileToBase64';
import { Input } from '../../../components/Input';
import { TabItems, Tabs } from '../../../components/Tabs';
import { MatchPhase } from '../matches';

const Group = () => {
  const router = useRouter();
  const { t } = useTranslation(['groups', 'common', 'matches']);
  const [isDropdownOpen, toggle] = useToggle();
  const [isInviteModalOpen, setInviteModalOpen, setInviteModalClose] = useModal();
  const [isMembersRankingOpen, setMembersRankingOpen, setMembersRankingClose] = useModal();
  const [isEditGroupModalOpen, setEditGroupModalOpen, setEditGroupModalClose] = useModal();
  const [isDeleteGroupModalOpen, setDeleteGroupModalOpen, setDeleteGroupModalClose] = useModal();
  const [isQuitGroupModalOpen, setQuitGroupModalOpen, setQuitGroupModalClose] = useModal();

  const { groupSlug } = router.query;

  const { profile, loading: fetchingUser } = useCurrentUser();

  const [{ data: groupInfo, fetching: fetchingGroupAndInfo }] = useGetGroupAndInfoQuery({
    variables: {
      groupSlug: groupSlug as string,
    },
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!groupSlug) {
      router.push('/app/groups');
    }
  }, [groupSlug]);

  const currentGroup = groupInfo?.group[0];

  useEffect(() => {
    if (!fetchingGroupAndInfo && !currentGroup) {
      router.push('/app/groups');
    }
  }, [fetchingGroupAndInfo, currentGroup]);

  if (!groupSlug) {
    return <LoadingFullScreen debugLabel="group slug page no group slug" />;
  }
  if (!currentGroup) {
    return <Loader />;
  }

  if (fetchingGroupAndInfo || fetchingUser || !profile) {
    return <Loader />;
  }

  const tabs: TabItems = {
    upcoming: {
      title: t('matches:upcoming'),
      component: <GroupMatches displayOnlyFinished={false} currentGroup={currentGroup} />,
    },
    finished: {
      title: t('matches:finished'),
      component: <GroupMatches displayOnlyFinished={true} currentGroup={currentGroup} />,
    },
  };

  const isAdmin = currentGroup.group_members.some((m) => m.profile_id === profile.id);

  return (
    <div>
      <div
        className="text-blue-500 font-semibold flex items-center cursor-pointer mb-4"
        onClick={() => {
          router.push({
            pathname: '/app/groups/',
          });
        }}
      >
        <ArrowDown className="transform rotate-90 w-5" /> <span>{t('common:back')}</span>
      </div>
      <div className="bg-white rounded-lg p-6 flex flex-col md:flex-row items-center">
        <div className="flex items-center flex-col md:flex-row">
          <img className="w-24 rounded-full" src={currentGroup.photo_url ?? DEFAULT_GROUP_PICTURE} alt="group" />
          <div className="ml-0 md:ml-8 flex flex-col items-center md:items-start mb-6 md:mb-0">
            <div className="font-black text-2xl">{currentGroup?.name}</div>
            <div className="text-gray-500 text-lg">
              {t('groups:membersCount', { count: currentGroup?.group_members_aggregate.aggregate?.count ?? 0 })}
            </div>
          </div>
        </div>
        <div className="ml-0 md:ml-auto flex">
          {isAdmin && (
            <Button onClick={setInviteModalOpen} variant="primary" Icon={icons.AddFriend} className="mr-4">
              {t('groups:inviteFriends')}
            </Button>
          )}
          <InviteLinkModal isOpen={isInviteModalOpen} setClose={setInviteModalClose} groupId={currentGroup.id} />
          <Dropdown
            isOpen={isDropdownOpen}
            setIsOpen={toggle}
            content={
              <>
                {isAdmin && (
                  <MenuItem onClick={setEditGroupModalOpen} icon={<Edit />}>
                    {t('groups:edit')}
                  </MenuItem>
                )}
                <MenuItem onClick={setQuitGroupModalOpen} icon={<Quit />}>
                  {t('groups:quitGroup')}
                </MenuItem>
                {isAdmin && (
                  <MenuItem onClick={setDeleteGroupModalOpen} danger icon={<Trash />}>
                    {t('groups:deleteGroup')}
                  </MenuItem>
                )}
              </>
            }
          >
            <DropdownTrigger />
          </Dropdown>
          <DeleteGroupModal
            isOpen={isDeleteGroupModalOpen}
            setClose={setDeleteGroupModalClose}
            groupName={currentGroup.name}
            groupId={currentGroup.id}
          />
          <EditGroupModal isOpen={isEditGroupModalOpen} setClose={setEditGroupModalClose} group={currentGroup} />
          <LeaveGroupModal
            isOpen={isQuitGroupModalOpen}
            setClose={setQuitGroupModalClose}
            groupId={currentGroup.id}
            groupName={currentGroup.name}
            redirect
          />
        </div>
      </div>
      <div className="flex-1 relative z-0 flex xl:overflow-hidden mt-6 h-screen">
        <main className="flex-1 relative z-0 xl:overflow-y-auto focus:outline-none xl:order-last">
          {/* Start main area*/}
          <div className="absolute inset-0 px-0 xl:pl-3">
            <GroupPointsAndRankInfos className="flex xl:hidden flex-col items-center mb-6" group={currentGroup} />
            <div className="text-2xl font-black text-black mb-4">{t('groups:membersForecasts')}</div>
            <div className="mb-6 block xl:hidden">
              <LinkButton onClick={setMembersRankingOpen} variant="blue">
                {t('groups:seeMembersRanking')}
              </LinkButton>
              <GroupMembersRankingModal
                isOpen={isMembersRankingOpen}
                setClose={setMembersRankingClose}
                group={currentGroup}
                isAdmin={isAdmin}
              />
            </div>
            <div className="bg-white rounded-lg">
              <Tabs tabs={tabs} />
            </div>
          </div>
          {/* End main area */}
        </main>
        <aside className="hidden relative xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96">
          {/* Start secondary column (hidden on smaller screens) */}
          <div className="absolute inset-0 pr-3">
            <div className="h-full space-y-4">
              <GroupPointsAndRankInfos group={currentGroup} />
              <div className="bg-white pt-6 rounded-lg">
                <div className="text-black font-black text-lg ml-6">{t('groups:whoIsTheBest')}</div>
                <GroupMembersRanking isAdmin={isAdmin} group={currentGroup} />
              </div>
            </div>
          </div>
          {/* End secondary column */}
        </aside>
      </div>
    </div>
  );
};

export default Group;

interface GroupMembersRankingProps {
  group: GetGroupAndInfoQuery['group'][0];
  isAdmin: boolean;
}

const GroupMembersRanking: React.FC<GroupMembersRankingProps> = ({ group, isAdmin }) => {
  return (
    <div className="max-h-96 overflow-y-auto divide-y">
      {group.groupMembersPointsAndRank.map(
        (member: GetGroupAndInfoQuery['group'][0]['groupMembersPointsAndRank'][0]) => (
          <RankItem isAdmin={isAdmin} key={member.profile_id} user={member} />
        ),
      )}
    </div>
  );
};

interface GroupMembersRankingModalProps {
  group: GetGroupAndInfoQuery['group'][0];
  isOpen: boolean;
  setClose: () => void;
  isAdmin: boolean;
}

const GroupMembersRankingModal: React.FC<GroupMembersRankingModalProps> = ({ isOpen, setClose, group, isAdmin }) => {
  const { t } = useTranslation(['groups']);
  return (
    <Modal title={t('groups:whoIsTheBest')} isOpen={isOpen} onClose={setClose}>
      <GroupMembersRanking isAdmin={isAdmin} group={group} />
      <div className="flex justify-end">
        <Button className="w-full mt-10" onClick={setClose}>
          {t('groups:close')}
        </Button>
      </div>
    </Modal>
  );
};

interface GroupPointsAndRankInfosProps {
  group: GetGroupAndInfoQuery['group'][0];
  className?: string;
}

const GroupPointsAndRankInfos: React.FC<GroupPointsAndRankInfosProps> = ({ group, className }) => {
  const { t } = useTranslation(['groups']);
  const ordinal = useOrdinalNumber(group.group_points_and_rank?.rank);
  const classNames = clsx('bg-white p-6 rounded-lg', className);

  return (
    <div className={classNames}>
      <div>
        <div className="text-black font-black text-lg">{t('groups:inBrief')}</div>
        <div className="items-center w-full space-x-8 flex">
          <ForecastPill status="perfect" amount={group.group_points_and_rank?.perfect ?? 0} />
          <ForecastPill status="partial" amount={group.group_points_and_rank?.partial ?? 0} />
          <ForecastPill status="miss" amount={group.group_points_and_rank?.miss ?? 0} />
        </div>
      </div>
      <div className="mt-6 ">
        <div className="text-gray-500 mb-4">{t('groups:groupRank')}</div>
        <div className="flex items-center mb-3">
          <Star className="text-purple-500 w-12 h-12" />
          <div className="ml-4">
            <span className="font-black text-3xl">{group?.group_points_and_rank?.rank}</span>
            <span className="text-sm">{ordinal}</span>
            <div className="text-gray-500">
              {t('groups:withCountPoints', { count: group?.group_points_and_rank?.total_points })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface InviteLinkModalProps {
  isOpen: boolean;
  setClose: () => void;
  groupId: string;
}

const InviteLinkModal: React.FC<InviteLinkModalProps> = ({ isOpen, setClose, groupId }) => {
  const { t } = useTranslation(['groups']);

  return (
    <Modal title={t('groups:inviteToJoinTheGroupModalTitle')} isOpen={isOpen} onClose={setClose}>
      <InviteLinkModalContent groupId={groupId} />
      <button className="opacity-0 absolute bottom-0"></button>
    </Modal>
  );
};

interface InviteLinkModalContentProps {
  groupId: string;
}

interface DaySelectType {
  label: string;
  value: number;
}

const InviteLinkModalContent: React.FC<InviteLinkModalContentProps> = ({ groupId }) => {
  const { t } = useTranslation(['groups']);
  const [{ data, fetching }, refetchInvitation] = useGetInvitationForGroupQuery({
    variables: {
      groupId,
    },
  });

  const [, createInvite] = useCreateInvitationForGroupMutation();

  const nmbDaysOpen: DaySelectType[] = [
    { value: 1, label: t('groups:inviteDaySelection', { count: 1 }) },
    { value: 7, label: t('groups:inviteDaySelection', { count: 7 }) },
    { value: 14, label: t('groups:inviteDaySelection', { count: 14 }) },
    { value: 30, label: t('groups:inviteDaySelection', { count: 30 }) },
  ];

  if (fetching || !data?.group_invite) {
    return <Loader />;
  }

  const isInviteAlreadyExist = data.group_invite.length > 0;
  let inviteLink = '';
  if (isInviteAlreadyExist) {
    const basePath = new URL(`${window.location}`).origin;
    inviteLink = `${basePath}/join/${data.group_invite[0].public_id}`;
  }

  const handleCopyToClipboard = async () => {
    await navigator.clipboard.writeText(inviteLink);
    showToast({
      title: 'Lien copié avec succès',
      description: 'Tu peux le partager à tes amis ou collègues',
      type: 'success',
      duration: 5000,
    });
  };

  return (
    <div className="mb-10 text-black">
      {!isInviteAlreadyExist && (
        <Formik
          initialValues={{ nbDaysOpen: nmbDaysOpen[2] }}
          onSubmit={async (values, formikHelpers) => {
            try {
              formikHelpers.setSubmitting(true);

              await createInvite({ groupId, nmbDaysOpen: values.nbDaysOpen.value });
              await refetchInvitation({ requestPolicy: 'cache-and-network' });
              formikHelpers.setSubmitting(false);
            } catch (e) {
              formikHelpers.setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="flex flex-col">
                <Select
                  name="nbDaysOpen"
                  type="text"
                  label={t('groups:nbDayInvitationIsValid')}
                  items={nmbDaysOpen}
                  itemToString={(i: DaySelectType) => i.label}
                />
                <Button className="mt-2" disabled={isSubmitting} type="submit">
                  {t('groups:generateInviteLink')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {isInviteAlreadyExist && (
        <div className="flex flex-col space-y-6">
          <div>{t('groups:sendThisLinkToYourFriends')}</div>
          <div>
            <div className="font-semibold mb-2">{t('groups:inviteLink')}</div>
            <div
              onClick={handleCopyToClipboard}
              className="w-full flex justify-between items-center px-4 py-3 border hover:border-black border-gray-300 rounded-lg cursor-pointer group"
            >
              <div className="overflow-x-scroll">{inviteLink}</div>
              <div>
                <Copy className="h-5 w-5 text-gray-400 group-hover:text-black" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface EditGroupModalProps {
  isOpen: boolean;
  setClose: () => void;
  group: GetGroupAndInfoQuery['group'][0] | GetUserGroupsByProfileIdQuery['group'][0];
}

export const EditGroupModal: React.FC<EditGroupModalProps> = ({ isOpen, setClose, group }) => {
  const { t } = useTranslation(['groups', 'common', 'settings']);
  const router = useRouter();
  const [, editGroupInfos] = useEditGroupInfosMutation();
  const validationSchema = yup.object().shape({
    // don't forget to pass the t function
    image: imageValidationSchema(t),
    groupName: yup
      .string()
      .required(t('groups:groupNameRequired'))
      .min(2, t('groups:groupNameMinChar'))
      .max(50, t('groups:groupMaxChar')),
  });

  return (
    <Modal onClose={setClose} isOpen={isOpen} title={t('groups:updateTheGroup')}>
      <div>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            groupName: group.name,
            image: undefined,
          }}
          onSubmit={async ({ groupName, image }) => {
            try {
              const groupNameHasChanged = groupName !== group.name;
              if (groupNameHasChanged || image) {
                const imageToUpload = image ? await fileToBase64((image as any) as File) : null;
                const { data } = await editGroupInfos({
                  name: groupName === group.name ? null : groupName,
                  groupId: group.id,
                  picture: imageToUpload,
                });

                router.push({
                  pathname: '/app/groups/[groupSlug]',
                  query: { groupSlug: data?.updateGroupInfo?.group?.slug },
                });
              }
              setClose();
            } catch (e) {
              console.error(e);
            }
          }}
        >
          {({ setFieldValue, errors, isSubmitting }) => (
            <Form>
              <div className="flex flex-col">
                <div className="mt-4 mb-6 w-full">
                  <Input
                    className="w-full"
                    name="groupName"
                    type="text"
                    placeholder={t('groups:enterYourGroupName')}
                    label={t('groups:name')}
                  />
                </div>
                <div className="flex flex-col">
                  <div className="font-semibold mb-2">{t('groups:groupPicture')}</div>
                  <div className="flex items-center mb-10">
                    <Field
                      label="Image de groupe"
                      name="image"
                      component={UploadImageInput}
                      title="Select a file"
                      initialImageUrl={group.photo_url ?? DEFAULT_GROUP_PICTURE}
                      setFieldValue={setFieldValue}
                      errorMessage={errors['image'] ? errors['image'] : undefined}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                {isSubmitting && <Loader variant="small" />}
                <Button disabled={isSubmitting} variant="secondary" onClick={setClose}>
                  {t('common:cancel')}
                </Button>
                <Button disabled={isSubmitting} className="ml-2" variant="primary" type="submit">
                  {t('groups:updateTheGroup')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

const GroupMatches = ({
  displayOnlyFinished,
  currentGroup,
}: {
  displayOnlyFinished: boolean;
  currentGroup: GetGroupAndInfoQuery['group'][0];
}) => {
  const { t } = useTranslation(['groups', 'common', 'matches']);

  const [{ data: allNextGames, fetching: fetchingAllNextGames }] = useGetAllGamesForGroupWithForecastsCountQuery({
    variables: {
      groupId: currentGroup?.id,
      isMatchOver: displayOnlyFinished,
      orderBy: displayOnlyFinished ? Order_By.Desc : Order_By.Asc,
    },
    pause: !currentGroup?.id,
    requestPolicy: 'cache-and-network',
  });

  let emptyTitle;
  let emptySubtitle;
  if (displayOnlyFinished) {
    emptyTitle = t('matches:noFinishedMatchYet');
    emptySubtitle = t('matches:finishedMatchesResultsWillAppearHere');
  } else {
    emptyTitle = t('matches:noMoreUpcomingMatch');
    emptySubtitle = t('matches:moreIsComingSoon');
  }
  if (!currentGroup) {
    return <Loader />;
  }

  if (fetchingAllNextGames) {
    return <Loader />;
  }

  return (
    <div>
      {!allNextGames?.pool.length && !allNextGames?.matchday.length && (
        <div className="flex flex-col justify-center items-center py-14">
          <MatchEmpty className="w-60" />
          <div className="flex flex-col items-center text-black max-w-80 mt-8">
            <span className="font-black text-center">{emptyTitle}</span>
            <span className="text-center">{emptySubtitle}</span>
          </div>
        </div>
      )}
      {displayOnlyFinished ? (
        <>
          {allNextGames?.pool.map((md) => (
            <MatchPhaseGroup
              key={md.id}
              mainHeader={t(`matches:${md.phase}`)}
              matches={md.matches}
              groupId={currentGroup?.id}
            />
          ))}
          {allNextGames?.matchday.map((md) => (
            <MatchPhaseGroup
              key={md.id}
              mainHeader={t('matches:groupPhase')}
              description={t('matches:matchDayCount', { matchday: md.number })}
              matches={md.matches}
              groupId={currentGroup?.id}
            />
          ))}
        </>
      ) : (
        <>
          {allNextGames?.matchday.map((md) => (
            <MatchPhaseGroup
              key={md.id}
              mainHeader={t('matches:groupPhase')}
              description={t('matches:matchDayCount', { matchday: md.number })}
              matches={md.matches}
              groupId={currentGroup?.id}
            />
          ))}
          {allNextGames?.pool.map((md) => (
            <MatchPhaseGroup
              key={md.id}
              mainHeader={t(`matches:${md.phase}`)}
              matches={md.matches}
              groupId={currentGroup?.id}
            />
          ))}
        </>
      )}
    </div>
  );
};
