import { GetGroupAndInfoQuery } from '../../generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from '../../hooks/useToggle';
import { Star, Trash } from '../../assets/icons';
import { Dropdown, DropdownTrigger, MenuItem } from '../Dropdown';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { DEFAULT_PROFILE_PICTURE } from '../../constants';

interface RankItemProps {
  user: GetGroupAndInfoQuery['group'][0]['groupMembersPointsAndRank'][0];
  isAdmin: boolean;
}

export const RankItem: React.FC<RankItemProps> = ({ user, isAdmin }) => {
  const { t } = useTranslation(['home']);
  const { profile } = useCurrentUser();
  const [isDropdownOpen, toggle] = useToggle();

  const isCurrentUser = profile?.id === user.profile_id;

  let starClassName;
  switch (user.rank) {
    case 1:
      starClassName = 'text-orange-500';
      break;
    case 2:
      starClassName = 'text-gray-400';
      break;
    case 3:
      starClassName = 'text-[#B36F0F]';
      break;
    default:
      starClassName = 'hidden';
      break;
  }

  return (
    <div className="group rounded-lg">
      <div className="flex items-center py-4 ml-6 pr-6">
        <div className="font-black text-sm">{user.rank}</div>
        <div className="w-4 mx-2">
          <Star className={starClassName} />
        </div>
        <div className="flex">
          <img
            className="w-10 h-10 rounded-full"
            src={user.profile?.user.photoUrl ?? DEFAULT_PROFILE_PICTURE}
            alt="group"
          />
          <div className="ml-4">
            <div className="font-semibold">{user.profile?.user.pseudo}</div>
            <div className="text-sm text-gray-500">{t('home:userPoints', { count: user.point_user })}</div>
          </div>
        </div>
        {isAdmin && !isCurrentUser && (
          <Dropdown
            isOpen={isDropdownOpen}
            setIsOpen={toggle}
            className="ml-auto hidden group-hover:flex"
            content={
              <>
                <MenuItem danger icon={<Trash />}>
                  {t('groups:deleteUser')}
                </MenuItem>
              </>
            }
          >
            <DropdownTrigger />
          </Dropdown>
        )}
      </div>
    </div>
  );
};
