import { GetAllGamesForGroupWithForecastsCountQuery } from '../../generated/graphql';
import React from 'react';
import { GameLine } from '../GameLine';
import { MatchGroupForecastsButton } from '../MatchGroupForecastButton';

interface MatchPhaseProps {
  mainHeader: string;
  description?: string;
  matches?: GetAllGamesForGroupWithForecastsCountQuery['matchday'][0]['matches'];
  groupId: string;
}

export const MatchPhaseGroup: React.FC<MatchPhaseProps> = ({ mainHeader, description, matches, groupId }) => {
  const matchesWithFakeForecasts = matches?.map((m) => {
    return { ...m, forecasts: [] };
  });
  if (!matches?.length) {
    return null;
  }
  return (
    <div>
      <div className="bg-gray-100 py-2 px-6">
        <span className="font-semibold text-sm text-black">{mainHeader}</span>
        {description && <span className="text-sm text-black ml-5">{description}</span>}
      </div>
      <div className="divide-y pl-6">
        {matchesWithFakeForecasts?.map((m) => (
          <GameLine
            className="pr-6"
            game={m}
            key={m.id}
            groupButton={<MatchGroupForecastsButton match={m} groupId={groupId} />}
          />
        ))}
      </div>
    </div>
  );
};
