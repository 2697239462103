import React from 'react';
import { useGetMatchWithMembersForecastsForGroupQuery } from '../../generated/graphql';
import { Loader } from '../Loader';
import { GameDateTime } from '../GameDateTime';
import { FlagsCode, TeamWithFlag } from '../TeamWithFlag';
import { Score } from '../Score';
import { ForecastPanel } from '../ForecastPanel';
import { Button } from '../Button';
import { GroupMemberForecast } from '../GroupMemberForecast';
import gql from 'graphql-tag';

gql`
  query getMatchWithMembersForecastsForGroup($groupId: uuid!, $matchId: uuid!) {
    matchByPk(id: $matchId) {
      awayScore
      homeScore
      isMatchOver
      playedAt
      id
      forecasts(
        order_by: { forecastsStatus: { forecastStatusPoints: { points: desc_nulls_last } } }
        where: { profile: { group_members: { group_id: { _eq: $groupId } } } }
      ) {
        ...ForecastsGroupMembers
      }
      teamAway {
        id
        playerTeam {
          id
          intlKey
        }
      }
      teamHome {
        id
        playerTeam {
          id
          intlKey
        }
      }
    }
  }
`;

interface GroupMatchDetailsModalProps {
  matchId: string;
  groupId: string;
  setClose: () => void;
  isOpen: boolean;
}

export const GroupMatchDetailsModalContent: React.FC<GroupMatchDetailsModalProps> = ({
  setClose,
  isOpen,
  matchId,
  groupId,
}) => {
  const [{ data, fetching }] = useGetMatchWithMembersForecastsForGroupQuery({
    variables: {
      groupId,
      matchId,
    },
    pause: false,
  });
  if (!data || fetching) {
    return <Loader />;
  }

  const match = data?.matchByPk;

  return (
    <div className="flex flex-col items-center pt-4 px-6">
      <GameDateTime className="mb-3" date={new Date(match?.playedAt)} />
      <div className="flex w-full mb-6">
        <TeamWithFlag
          className="w-5/12 inline-flex justify-end"
          teamCode={match?.teamHome?.playerTeam.intlKey as FlagsCode}
          variant="regular"
        />
        <div className="w-2/12 inline-flex justify-center">
          <Score scoreHomeTeam={match?.homeScore} scoreAwayTeam={match?.awayScore} />
        </div>
        <TeamWithFlag
          className="w-5/12 inline-flex justify-end"
          teamCode={match?.teamAway?.playerTeam.intlKey as FlagsCode}
          variant="regular"
          reverse
        />
      </div>
      <ForecastPanel.TriggerForecastPanel setCloseModal={setClose} matchId={matchId}>
        <Button onClick={setClose} disabled={match?.isMatchOver}>
          Pronostiquer
        </Button>
      </ForecastPanel.TriggerForecastPanel>
      <div className="w-full space-y-1 max-h-72 overflow-y-auto mt-8 mb-4">
        {match?.forecasts.map((f) => (
          <GroupMemberForecast key={f.profile.id} forecast={f} />
        ))}
      </div>
    </div>
  );
};
