import { GetAllGamesForGroupWithForecastsCountQuery } from '../../generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, useModal } from '../Modal';
import { LinkButton } from '../LinkButton';
import { GroupMatchDetailsModalContent } from '../GroupMatchDetailsModalContent';

interface MatchGroupForecastButtonProps {
  match: GetAllGamesForGroupWithForecastsCountQuery['matchday'][0]['matches'][0];
  groupId: string;
}

export const MatchGroupForecastsButton: React.FC<MatchGroupForecastButtonProps> = ({ match, groupId }) => {
  const { t } = useTranslation(['groups', 'common']);
  const [isOpen, setOpen, setClose] = useModal();
  return (
    <>
      <LinkButton variant="blue" onClick={setOpen}>
        {t('groups:forecastsCount', { count: match.forecasts_aggregate.aggregate?.count ?? 0 })}
      </LinkButton>
      <Modal isEmptyTemplate onClose={setClose} isOpen={isOpen}>
        {isOpen && (
          <GroupMatchDetailsModalContent matchId={match.id} setClose={setClose} isOpen={isOpen} groupId={groupId} />
        )}
      </Modal>
    </>
  );
};
