import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Dropdown, DropdownTrigger, MenuItem } from '../../../components/Dropdown';
import { AddFriend, Edit, Quit, Trash } from '../../../assets/icons';
import { useToggle } from '../../../hooks/useToggle';
import { Button } from '../../../components/Button';
import { Modal, useModal } from '../../../components/Modal';
import { Field, Form, Formik } from 'formik';
import { Input } from '../../../components/Input';
import { useRouter } from 'next/router';
import gql from 'graphql-tag';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { Loader } from '../../../components/Loader';
import {
  GetUserGroupsByProfileIdQuery,
  GroupPointsAndRankForRankingFragment,
  useCreateGroupMutation,
  useDeleteGroupMemberMutation,
  useDeleteGroupMutation,
  useGetIntergroupRankingQuery,
  useGetInterMyGroupsRankingQuery,
  useGetUserGroupsByProfileIdQuery,
} from '../../../generated/graphql';
import { GroupEmpty } from '../../../assets/illustration';
import { imageValidationSchema, UploadImageInput } from '../../../components/UploadImageInput';
import { AnimateSharedLayout, motion } from 'framer-motion';
import { ForecastPill } from '../../../components/PersonalProfileHeader';
import { useOrdinalNumber } from '../../../hooks/useOrdinalNumber';
import { LinkButton } from '../../../components/LinkButton';
import { DEFAULT_GROUP_PICTURE } from '../../../constants';
import { fileToBase64 } from '../../../utils/fileToBase64';
import * as yup from 'yup';
import { EditGroupModal } from './[groupSlug]';
import { showToast } from '../../../components/Toast';

gql`
  query getUserGroupsByProfileId($profileId: uuid!) {
    group(where: { group_members: { profile_id: { _eq: $profileId } } }) {
      id
      name
      slug
      photo_url
      adminMembers: group_members(where: { is_admin: { _eq: true }, _and: { profile_id: { _eq: $profileId } } }) {
        is_admin
        id
      }
      group_points_and_rank {
        rank
        group_id
      }
      group_members_aggregate {
        aggregate {
          count
        }
      }
    }
  }

  query getIntergroupRanking {
    group_points_and_rank(order_by: { rank: asc }) {
      ...groupPointsAndRankForRanking
    }
  }

  query getInterMyGroupsRanking($profileId: uuid!) {
    group_points_and_rank(
      where: { group: { group_members: { profile_id: { _eq: $profileId } } } }
      order_by: { rank: asc }
    ) {
      ...groupPointsAndRankForRanking
    }
  }

  fragment groupPointsAndRankForRanking on group_points_and_rank {
    group_id
    miss
    partial
    perfect
    rank
    total_points
    group {
      name
      id
      photo_url
    }
  }

  mutation createGroup($cupId: uuid!, $groupName: String!, $picture: String) {
    registerGroup(cupId: $cupId, groupName: $groupName, picture: $picture) {
      groupId
    }
  }

  mutation deleteGroup($groupId: uuid!) {
    delete_group_by_pk(id: $groupId) {
      id
    }
  }

  mutation deleteGroupMember($groupId: uuid!, $profileId: uuid!) {
    delete_group_member(where: { profile_id: { _eq: $profileId }, _and: { group_id: { _eq: $groupId } } }) {
      returning {
        group_id
      }
    }
  }
`;

type TabsType = 'allGroups' | 'myGroups';
interface TabItem {
  id: TabsType;
  label: string;
  isActive: boolean;
}

const Groups = () => {
  const { t } = useTranslation(['groups']);
  const { profile, loading } = useCurrentUser();
  const [isRankingModalOpen, setRankingModalOpen, setRankingModalClose] = useModal();

  let [activeTab, setActiveTab] = useState<TabsType>('allGroups');
  const tabs: TabItem[] = [
    {
      id: 'allGroups',
      label: t('groups:allGroups'),
      isActive: activeTab === 'allGroups',
    },
    {
      id: 'myGroups',
      label: t('groups:myGroups'),
      isActive: activeTab === 'myGroups',
    },
  ];

  const Components = {
    allGroups: GeneralGroupsRanking,
    myGroups: MyGroupsRanking,
  };

  const ActiveTabComponent: React.VFC = Components[activeTab];

  const profileId = profile?.id;

  const [{ data: userGroupsData, fetching: fetchingUserGroups }, refetchGroups] = useGetUserGroupsByProfileIdQuery({
    variables: {
      profileId,
    },
    requestPolicy: 'cache-and-network',
  });

  const onGroupCreated = () => {
    refetchGroups({ requestPolicy: 'network-only' });
  };

  const userHasNoGroup = userGroupsData?.group.length === 0;

  if (loading || fetchingUserGroups) {
    return <Loader />;
  }

  return (
    <div>
      <div className="font-black text-2xl mb-4">{t('groups:groupsTitle')}</div>
      <div className="flex space-x-0 md:space-x-6">
        <div className="w-full md:w-auto">
          <div className="mb-4 block md:hidden">
            <LinkButton variant="blue" onClick={setRankingModalOpen}>
              {t('groups:seeIntergroupRanking')}
            </LinkButton>
            <GroupRankingModal isOpen={isRankingModalOpen} setClose={setRankingModalClose} />
          </div>
          {userHasNoGroup ? (
            <NoGroup onGroupCreated={onGroupCreated} />
          ) : (
            <ul className="space-y-4">
              <CreateGroupItem onGroupCreated={onGroupCreated} />
              {userGroupsData?.group.map((group) => (
                <GroupItem
                  key={group.id}
                  group={group}
                  isAdmin={group.adminMembers.length > 0}
                  onGroupLeaved={onGroupCreated}
                />
              ))}
            </ul>
          )}
        </div>
        <div className="hidden md:block bg-white rounded-lg flex-grow pl-6 pt-6">
          <div className="font-black">{t('groups:pronostixGroupsRanking')}</div>
          <AnimateSharedLayout>
            <div className="flex p-6 pb-0 pl-0 ml-6 border-b border-gray-100 space-x-8">
              {tabs.map(({ label, isActive, id }) => (
                <div
                  onClick={() => setActiveTab(id)}
                  className={`relative font-semibold hover:text-blue-500 ${
                    isActive ? 'text-blue-500' : 'text-gray-500'
                  } pb-2 cursor-pointer`}
                  key={id}
                >
                  <span>{label}</span>
                  {isActive && (
                    <motion.div
                      style={{ height: '1px' }}
                      className="w-full absolute bg-blue-500 bottom-0"
                      layoutId="navigation-tabs-underline"
                      transition={{ duration: 0.2 }}
                    />
                  )}
                </div>
              ))}
            </div>
          </AnimateSharedLayout>
          <div>
            <ActiveTabComponent />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Groups;

const NoGroup = ({ onGroupCreated }: { onGroupCreated: () => any }) => {
  const { t } = useTranslation(['groups']);
  const [isOpen, setOpen, setClose] = useModal();

  return (
    <>
      <div className="bg-white w-full rounded-lg p-12 flex flex-col justify-center items-center">
        <GroupEmpty />
        <div className="font-black mt-8">{t('groups:youHaveNoGroupYetAsk')}</div>
        <div className="text-center w-2/3 text-gray-500">{t('groups:createOneOrWaitAnInvite')}</div>
        <Button onClick={setOpen} className="mt-4" Icon={AddFriend}>
          {t('groups:createAGroup')}
        </Button>
      </div>
      <CreateGroupModal onSuccess={onGroupCreated} isOpen={isOpen} setClose={setClose} />
    </>
  );
};

const RankDisplay = ({ rank }: { rank: number }) => {
  const suffix = useOrdinalNumber(rank);

  return (
    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 border-2 border-white bg-purple-500 text-white rounded-full py-1 px-3 text-sm flex">
      {rank}
      <span className="text-xs self-start">{suffix}</span>
    </div>
  );
};

interface GroupItemProps {
  isAdmin: boolean;
  group: GetUserGroupsByProfileIdQuery['group'][0];
  onGroupLeaved: () => void;
}

const GroupItem: React.FC<GroupItemProps> = ({ isAdmin, group, onGroupLeaved }) => {
  const { t } = useTranslation(['groups', 'common']);
  const [isDropdownOpen, toggle] = useToggle();
  const [isDeleteGroupModalOpen, setDeleteGroupModalOpen, setDeleteGroupModalClose] = useModal();
  const [isLeaveGroupModalOpen, setLeaveGroupModalOpen, setLeaveGroupModalClose] = useModal();
  const [isEditGroupModalOpen, setEditGroupModalOpen, setEditGroupModalClose] = useModal();
  const { id, photo_url, name, group_points_and_rank, group_members_aggregate, slug } = group;

  const router = useRouter();

  return (
    <>
      <li className="col-span-1 flex bg-white rounded-lg hover:shadow-soft group cursor-pointer">
        <div
          className="flex items-center p-6 flex-grow"
          onClick={() => {
            router.push({
              pathname: '/app/groups/[groupSlug]',
              query: { groupSlug: slug },
            });
          }}
        >
          <div className="relative">
            <img className="w-16 rounded-full" src={photo_url ?? DEFAULT_GROUP_PICTURE} alt="group" />
            <RankDisplay rank={group_points_and_rank?.rank} />
          </div>
          <div className="flex flex-col ml-4">
            <div className="font-black text-blue-500 group-hover:underline">{name}</div>
            <div className="text-gray-500">
              {t('groups:membersCount', { count: group_members_aggregate.aggregate?.count ?? 0 })}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center pr-6">
          <Dropdown
            isOpen={isDropdownOpen}
            setIsOpen={toggle}
            className="ml-auto"
            content={
              <>
                {isAdmin && (
                  <MenuItem onClick={setEditGroupModalOpen} icon={<Edit />}>
                    {t('groups:edit')}
                  </MenuItem>
                )}
                <MenuItem
                  onClick={(event) => {
                    setLeaveGroupModalOpen();
                  }}
                  icon={<Quit />}
                >
                  {t('groups:quitGroup')}
                </MenuItem>
                {isAdmin && (
                  <MenuItem
                    onClick={(event) => {
                      setDeleteGroupModalOpen();
                    }}
                    danger
                    icon={<Trash />}
                  >
                    {t('groups:deleteGroup')}
                  </MenuItem>
                )}
              </>
            }
          >
            <DropdownTrigger />
          </Dropdown>
        </div>
      </li>
      <DeleteGroupModal
        isOpen={isDeleteGroupModalOpen}
        setClose={setDeleteGroupModalClose}
        groupId={id}
        groupName={name}
      />
      <LeaveGroupModal
        isOpen={isLeaveGroupModalOpen}
        setClose={setLeaveGroupModalClose}
        groupId={id}
        groupName={name}
        onSuccess={onGroupLeaved}
      />
      <EditGroupModal isOpen={isEditGroupModalOpen} setClose={setEditGroupModalClose} group={group} />
    </>
  );
};

const CreateGroupItem = ({ onGroupCreated }: { onGroupCreated: () => any }) => {
  const { t } = useTranslation(['groups', 'common']);
  const [isOpen, setOpen, setClose] = useModal();

  return (
    <>
      <Button className="flex items-center w-full" onClick={setOpen}>
        + {t('groups:createNewGroup')}
      </Button>
      <CreateGroupModal onSuccess={onGroupCreated} isOpen={isOpen} setClose={setClose} />
    </>
  );
};

interface CreateGroupModalProps {
  isOpen: boolean;
  setClose: () => void;
  onSuccess: () => any;
}

export const CreateGroupModal: React.FC<CreateGroupModalProps> = ({ onSuccess, isOpen, setClose }) => {
  const { t } = useTranslation(['groups', 'common', 'settings']);
  const { profile } = useCurrentUser();
  const [, createGroup] = useCreateGroupMutation();
  const validationSchema = yup.object().shape({
    // don't forget to pass the t function
    image: imageValidationSchema(t),
    groupName: yup
      .string()
      .required(t('groups:groupNameRequired'))
      .min(2, t('groups:groupNameMinChar'))
      .max(50, t('groups:groupMaxChar')),
  });

  return (
    <Modal onClose={setClose} isOpen={isOpen} title={t('groups:createAGroup')}>
      <div>
        <div className="text-black">{t('groups:chooseAGroupNameAndShare')}</div>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            groupName: '',
            image: undefined,
          }}
          onSubmit={async (values) => {
            try {
              const image = values.image ? await fileToBase64((values.image as any) as File) : null;
              await createGroup({ groupName: values.groupName, cupId: profile?.cupId, picture: image });
              setClose();
              onSuccess();
            } catch (e) {
              console.error(e);
            }
          }}
        >
          {({ setFieldValue, errors, isSubmitting }) => (
            <Form>
              <div className="flex flex-col">
                <div className="mt-4 mb-6 w-full">
                  <Input
                    className="w-full"
                    name="groupName"
                    type="text"
                    placeholder={t('groups:enterYourGroupName')}
                    label={t('groups:name')}
                  />
                </div>
                <div className="flex flex-col">
                  <div className="font-semibold mb-2">{t('groups:groupPicture')}</div>
                  <div className="flex items-center mb-10">
                    <Field
                      label="Image de groupe"
                      name="image"
                      component={UploadImageInput}
                      title="Select a file"
                      initialImageUrl={DEFAULT_GROUP_PICTURE}
                      setFieldValue={setFieldValue}
                      errorMessage={errors['image'] ? errors['image'] : undefined}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                {isSubmitting && <Loader variant="small" />}
                <Button disabled={isSubmitting} variant="secondary" onClick={setClose}>
                  {t('common:cancel')}
                </Button>
                <Button disabled={isSubmitting} className="ml-2" variant="primary" type="submit">
                  {t('groups:createTheGroup')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

interface DeleteGroupModalProps {
  isOpen: boolean;
  setClose: () => void;
  groupName: string;
  groupId: string;
  redirect?: boolean;
}

export const DeleteGroupModal: React.FC<DeleteGroupModalProps> = ({
  isOpen,
  setClose,
  groupName,
  groupId,
  redirect,
}) => {
  const { t } = useTranslation(['groups']);
  const [, deleteGroup] = useDeleteGroupMutation();
  const router = useRouter();
  const handleGroupDeletion = async () => {
    try {
      await deleteGroup({ groupId });
      if (redirect) {
        router.push('/app/groups');
      }
      setClose();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Modal title={t('groups:deleteGroupConfirmationModaleTitle')} isOpen={isOpen} onClose={setClose}>
      <div className="mb-10">
        <Trans i18nKey="groups:deleteGroupConfirmation" values={{ groupName }}>
          You will no longer be part of the group <strong>{{ groupName }}</strong>. Like all the other members.
        </Trans>
      </div>
      <div className="flex justify-end">
        <Button variant="secondary" onClick={setClose}>
          {t('common:cancel')}
        </Button>
        <Button onClick={handleGroupDeletion} className="ml-2" variant="primary">
          {t('groups:deleteGroup')}
        </Button>
      </div>
    </Modal>
  );
};

const GeneralGroupsRanking: React.FC = () => {
  const [{ data, fetching }] = useGetIntergroupRankingQuery({ requestPolicy: 'cache-and-network' });
  if (fetching || !data) {
    return <Loader />;
  }
  return <GroupRanking ranking={data?.group_points_and_rank} />;
};

const MyGroupsRanking: React.FC = () => {
  const { profile } = useCurrentUser();
  const [{ data, fetching }] = useGetInterMyGroupsRankingQuery({
    variables: { profileId: profile?.id },
    pause: !profile,
    requestPolicy: 'cache-and-network',
  });

  if (!profile) {
    return <Loader />;
  }
  if (fetching || !data) {
    return <Loader />;
  }
  return <GroupRanking ranking={data.group_points_and_rank} />;
};

interface GroupRankingProps {
  ranking: GroupPointsAndRankForRankingFragment[];
}

const GroupRanking: React.FC<GroupRankingProps> = ({ ranking }) => {
  return (
    <div>
      {ranking.map(({ rank, total_points, miss, perfect, partial, group }) => (
        <GroupRankingItem
          key={group?.id}
          totalPoints={total_points}
          rank={rank}
          miss={miss}
          partial={partial}
          perfect={perfect}
          group={group}
        />
      ))}
    </div>
  );
};

interface GroupRankingItemProps {
  rank: number;
  totalPoints: number;
  miss: number;
  perfect: number;
  partial: number;
  group: GroupPointsAndRankForRankingFragment['group'];
}

const GroupRankingItem: React.FC<GroupRankingItemProps> = ({ rank, partial, perfect, miss, totalPoints, group }) => {
  const { t } = useTranslation(['forecastPanel']);
  return (
    <div className="flex items-center py-5 pr-6">
      <div className="font-black">{rank}</div>
      <div className="flex ml-5">
        <img className="rounded-full w-10 h-10" src={group?.photo_url ?? DEFAULT_GROUP_PICTURE} alt="group" />
        <div className="flex flex-col ml-5">
          <div className="font-semibold">{group?.name}</div>
          <div className="text-sm text-gray-500">{t('forecastPanel:point', { count: totalPoints })}</div>
        </div>
      </div>
      <div className="hidden lg:flex ml-auto space-x-4">
        <ForecastPill amount={perfect} status="perfect" />
        <ForecastPill amount={partial} status="partial" />
        <ForecastPill amount={miss} status="miss" />
      </div>
    </div>
  );
};

interface GroupRankingModalProps {
  isOpen: boolean;
  setClose: () => void;
}

const GroupRankingModal: React.FC<GroupRankingModalProps> = ({ isOpen, setClose }) => {
  const { t } = useTranslation(['groups']);
  let [activeTab, setActiveTab] = useState<TabsType>('allGroups');
  const tabs: TabItem[] = [
    {
      id: 'allGroups',
      label: t('groups:allGroups'),
      isActive: activeTab === 'allGroups',
    },
    {
      id: 'myGroups',
      label: t('groups:myGroups'),
      isActive: activeTab === 'myGroups',
    },
  ];

  const Components = {
    allGroups: GeneralGroupsRanking,
    myGroups: MyGroupsRanking,
  };

  const ActiveTabComponent: React.VFC = Components[activeTab];
  return (
    <Modal title={t('groups:pronostixGroupsRanking')} isOpen={isOpen} onClose={setClose}>
      <AnimateSharedLayout>
        <div className="flex p-6 pb-0 pl-0 ml-6 border-b border-gray-100 space-x-8">
          {tabs.map(({ label, isActive, id }) => (
            <div
              onClick={() => setActiveTab(id)}
              className={`relative font-semibold hover:text-blue-500 ${
                isActive ? 'text-blue-500' : 'text-gray-500'
              } pb-2 cursor-pointer`}
              key={id}
            >
              <span>{label}</span>
              {isActive && (
                <motion.div
                  style={{ height: '1px' }}
                  className="w-full absolute bg-blue-500 bottom-0"
                  layoutId="navigation-tabs-underline"
                  transition={{ duration: 0.2 }}
                />
              )}
            </div>
          ))}
        </div>
      </AnimateSharedLayout>
      <div className="max-h-72 overflow-y-auto">
        <ActiveTabComponent />
      </div>
      <div className="flex justify-end">
        <Button onClick={setClose}>{t('groups:close')}</Button>
      </div>
    </Modal>
  );
};

interface LeaveGroupModalProps {
  isOpen: boolean;
  setClose: () => void;
  groupId: string;
  groupName: string;
  redirect?: boolean;
  onSuccess?: () => void;
}

export const LeaveGroupModal: React.FC<LeaveGroupModalProps> = ({
  isOpen,
  setClose,
  groupId,
  groupName,
  redirect,
  onSuccess,
}) => {
  const { t } = useTranslation(['groups']);
  const [, deleteGroupMember] = useDeleteGroupMemberMutation();
  const { profile } = useCurrentUser();
  const router = useRouter();
  const handleGroupDeletion = async () => {
    const result = await deleteGroupMember({ groupId, profileId: profile?.id });

    if (!result.error) {
      showToast({
        title: t('groups:groupLeaved'),
        description: t('groups:groupLeavedDescription'),
        type: 'success',
        duration: 5000,
      });
      if (redirect) {
        router.push('/app/groups');
      }
      if (onSuccess) {
        onSuccess();
      }
    } else {
      showToast({
        title: t('groups:groupLeavedFailed'),
        description: t('groups:groupLeavedDescriptionFailed'),
        type: 'error',
        duration: 5000,
      });
      console.error(result.error.message);
    }
    setClose();
  };
  return (
    <Modal title={t('groups:quitGroupConfirmationModaleTitle')} isOpen={isOpen} onClose={setClose}>
      <div className="mb-10">
        <Trans i18nKey="groups:quitGroupConfirmation" values={{ groupName }}>
          You will no longer be part of the group <strong>{{ groupName }}</strong>.
        </Trans>
      </div>
      <div className="flex justify-end">
        <Button variant="secondary" onClick={setClose}>
          {t('common:cancel')}
        </Button>
        <Button onClick={handleGroupDeletion} className="ml-2" variant="primary">
          {t('groups:quitGroup')}
        </Button>
      </div>
    </Modal>
  );
};
