import { useTranslation } from 'react-i18next';

type OrdinalTypes = 'zero' | 'one' | 'two' | 'few' | 'many' | 'other';
type OrdinalMap = Record<OrdinalTypes, string>;
type LocalMap = Record<string, OrdinalMap>;

export const ordinals: LocalMap = {
  fr: {
    zero: 'er',
    one: 'er',
    two: 'nd',
    few: 'ème',
    many: 'ème',
    other: 'ème',
  },
  en: {
    one: 'st',
    two: 'nd',
    few: 'rd',
    many: 'th',
    zero: 'th',
    other: 'th',
  },
};

export const useOrdinalNumber = (value: number): string => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const keyLocal = locale?.split('-')[0];
  const pr = new Intl.PluralRules(locale ?? 'en', { type: 'ordinal' });
  const suffix = ordinals[keyLocal ?? 'en'][pr.select(value)];

  return suffix;
};
