import React from 'react';
import Pronostix from '../../assets/icons/Pronostix';
import { LinkButton } from '../LinkButton';
import { useTranslation } from 'react-i18next';
import { gql } from 'urql';
import { useGetDataForPersonnalProfileHeaderQuery } from '../../generated/graphql';
import { Loader } from '../Loader';
import { useOrdinalNumber } from '../../hooks/useOrdinalNumber';
import { DEFAULT_PROFILE_PICTURE } from '../../constants';
import { useModal } from '../Modal';
import { RulesModal } from '../RulesModal';

interface PersonalProfileHeaderProps {
  profileId: string;
}

gql`
  query getDataForPersonnalProfileHeader($profileId: uuid!) {
    profileByPk(id: $profileId) {
      id
      points {
        profileId
        rank
        totalPoints
      }
      user {
        id
        pseudo
        photoUrl
      }
      forecasts_status {
        in_progress
        miss
        partial
        perfect
        profile_id
      }
    }
  }
`;

export type PillTypes = 'perfect' | 'partial' | 'miss' | 'in_progress';

export const forecastPillClasses: Record<PillTypes, string> = {
  miss: 'bg-red-100 text-red-500',
  partial: 'bg-orange-100 text-orange-500',
  perfect: 'bg-green-100 text-green-500',
  in_progress: 'bg-blue-100 text-blue-500',
};

export const ForecastPill = ({ amount, status }: { amount: number; status: PillTypes }) => {
  return (
    <div className="flex items-center justify-center">
      <div className={`rounded-full ${forecastPillClasses[status]} w-8 h-8 flex justify-center items-center p-1.5`}>
        <Pronostix className="w-full h-full" />
      </div>
      <span className="font-semibold ml-2">{amount}</span>
    </div>
  );
};

export const RankDisplay = ({ rank }: { rank: number }) => {
  const suffix = useOrdinalNumber(rank);

  return (
    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 border-2 border-white bg-purple-500 text-white rounded-full py-1 px-3 text-sm flex">
      {rank}
      <span className="text-xs self-start">{suffix}</span>
    </div>
  );
};

export const PersonalProfileHeader = ({ profileId }: PersonalProfileHeaderProps) => {
  const { t } = useTranslation(['common', 'home']);
  const [isOpen, setOpen, setClose] = useModal();
  const [{ data, fetching, error }] = useGetDataForPersonnalProfileHeaderQuery({
    variables: {
      profileId,
    },
    requestPolicy: 'cache-and-network',
  });

  if (fetching) {
    return <Loader />;
  }

  if (error || !data?.profileByPk) {
    return <div>Something went bad retrieving the user profile...</div>;
  }
  const profileByPk = data.profileByPk;

  return (
    <div className="bg-white rounded-lg p-6 flex md:flex-row flex-col">
      <div className="flex">
        <div className="relative">
          <img
            className="rounded-full w-32 h-auto"
            src={profileByPk.user.photoUrl ?? DEFAULT_PROFILE_PICTURE}
            alt="profile"
          />
          <RankDisplay rank={profileByPk.points?.rank ?? 0} />
        </div>
        <div className="ml-6 flex flex-col md:justify-between justify-center">
          <span className="text-2xl font-black">{profileByPk.user.pseudo}</span>
          <div className="flex sm:items-center flex-col sm:flex-row">
            <span className="text-gray-500 text-lg">
              {t('home:userPoints', { count: profileByPk.points?.totalPoints || 0 })}
            </span>
            <div className="sm:ml-2">
              <LinkButton variant="blue" onClick={setOpen}>
                {t('home:seeRules')}
              </LinkButton>
            </div>
          </div>
          <div className="md:mt-auto mt-0">
            <div className="items-center w-full space-x-8 md:flex hidden">
              <ForecastPill status="perfect" amount={profileByPk.forecasts_status?.perfect ?? 0} />
              <ForecastPill status="partial" amount={profileByPk.forecasts_status?.partial ?? 0} />
              <ForecastPill status="miss" amount={profileByPk.forecasts_status?.miss ?? 0} />
            </div>
          </div>
        </div>
      </div>
      <div className="items-center w-full space-x-8 md:hidden flex py-6 justify-center">
        <ForecastPill status="perfect" amount={profileByPk.forecasts_status?.perfect ?? 0} />
        <ForecastPill status="partial" amount={profileByPk.forecasts_status?.partial ?? 0} />
        <ForecastPill status="miss" amount={profileByPk.forecasts_status?.miss ?? 0} />
      </div>
      <div className="md:ml-auto ml-0 md:pt-0 pt-4 md:border-l md:border-t-0 border-t pl-6 flex items-center justify-center">
        <div className="h-20 flex flex-col justify-center">
          <div className="flex items-center">
            <div className="rounded-full bg-blue-100 text-blue-500 w-10 h-10 flex justify-center items-center p-2">
              <Pronostix className="w-full h-full" />
            </div>
            <span className="ml-2 text-2xl font-semibold">{profileByPk.forecasts_status?.in_progress ?? 0}</span>
            <span className="ml-2">{t('home:inProgress')}</span>
          </div>
        </div>
      </div>
      <RulesModal isOpen={isOpen} setClose={setClose} />
    </div>
  );
};
